import react from 'react'
import PaymentGroup from './PaymentGroup'
import Box from './UI/Box'
import styled from 'styled-components'

const PaymentGroupStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
`

const PaymentGroups = ({ paymentMethods, onSelected }) => {
  return (
    <Box title='Metoda płatności'>
      <PaymentGroupStyles>
        {paymentMethods?.map((p) => (
          <PaymentGroup
            key={p.id}
            id={p.id}
            name={p.name}
            image={p.image}
            selected={p.selected}
            onSelected={onSelected}
          />
        ))}
      </PaymentGroupStyles>
    </Box>
  )
}

export default PaymentGroups
