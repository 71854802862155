import React from 'react'
import { useState } from 'react/cjs/react.production.min'
import styled from 'styled-components'
import Box from './UI/Box'

const PersonalInformationStyles = styled.ul`
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 26px;

    &.error {
      margin-bottom: 2px;

      .inner {
        > input {
          border-color: red;
        }
        span {
          color: red;
        }
      }
    }

    & .inner {
      > input {
        width: 100%;
      }
    }
  }

  & > li > label,
  & li p {
    flex: 1 0 120px;
    max-width: 150px;
  }

  & > li > label + * {
    flex: 0 0 250px;
  }
`

const PersonalInformation = ({
  name,
  nameChangeHandler,
  nameValidationMessage,
  email,
  emailChangeHandler,
  emailValidationMessage
}) => {
  return (
    <Box title='Dane personalne'>
      <PersonalInformationStyles>
        <li className={`${nameValidationMessage ? 'error' : ''}`}>
          <label htmlFor='name'>Imię i nazwisko</label>
          <div className='inner'>
            <input
              type='text'
              id='name'
              value={name}
              onChange={nameChangeHandler}
            />
            <span>{nameValidationMessage}</span>
          </div>
        </li>
        <li className={`${emailValidationMessage ? 'error' : ''}`}>
          <label htmlFor='email'>Email</label>
          <div className='inner'>
            <input
              type='email'
              id='email'
              value={email}
              onChange={emailChangeHandler}
            />
            <span>{emailValidationMessage}</span>
          </div>
        </li>
      </PersonalInformationStyles>
    </Box>
  )
}

export default PersonalInformation
