import React from 'react'
import styled from 'styled-components'
import Box from './UI/Box'

const BlikCodeStyles = styled.ul`
  li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 26px;

    &.error {
      margin-bottom: 2px;

      .inner {
        > input {
          border-color: red;
        }
        span {
          color: red;
        }
      }
    }

    & .inner {
      > input {
        width: 100%;
      }
    }
  }

  & > li > label,
  & li p {
    flex: 1 0 120px;
    max-width: 150px;
  }

  & > li > label + * {
    flex: 0 0 250px;
  }
`

const BlikCode = ({
  blikCode,
  blikCodeChangeHandler,
  blikCodeValidationMessage
}) => {
  return (
    <Box title='Kod Blik'>
      <BlikCodeStyles>
        <li className={`${blikCodeValidationMessage ? 'error' : ''}`}>
          <label htmlFor='blik'>Blik</label>
          <div className='inner'>
            <input
              type='text'
              id='blik'
              value={blikCode}
              onChange={blikCodeChangeHandler}
            />
            <span>{blikCodeValidationMessage}</span>
          </div>
        </li>
      </BlikCodeStyles>
    </Box>
  )
}

export default BlikCode
