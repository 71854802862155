import React from 'react'
import styled from 'styled-components'
import Spinner from './Spinner'

const LoadingOverlayStyles = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #aabfff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`

const LoadingOverlay = () => {
  return (
    <LoadingOverlayStyles>
      <Spinner />
    </LoadingOverlayStyles>
  )
}

export default LoadingOverlay
