import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Box from './UI/Box'
import Loading from './UI/Loading'

const SuccessTransactionStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  & > * {
    flex-basis: 100%;
  }

  & > p {
    text-align: center;
  }
`

const SuccessTransaction = () => {
  const { hash } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [redirectUrl, setRedirectUrl] = useState(null)
  const [success, setSuccess] = useState(false)

  const fetchStatusHandler = useCallback(async () => {
    try {
      let url = `/api/paybylink/validate/${hash}`
      let response = await fetch(url)
      if (response.ok) {
        let body = await response.json()
        if (!body.hasResult) {
          setTimeout(() => {
            fetchStatusHandler()
          }, 5000)
          return
        }

        setSuccess(body.success)
        if (body.returnUrl) {
          setRedirectUrl(body.returnUrl)
          setTimeout(() => {
            window.location.href = body.returnUrl
          }, 10000)
        }
        setIsLoading(false)
        return
      }
      setTimeout(() => {
        fetchStatusHandler()
      }, 5000)
    } catch (ex) {
      setIsLoading(false)
      setSuccess(false)
    }
  }, [hash])

  useEffect(() => {
    fetchStatusHandler()
  }, [fetchStatusHandler])

  let body

  if (isLoading) {
    body = (
      <>
        <p>Twoja transakcja jest walidowana</p>
        <Loading />
      </>
    )
  } else if (success) {
    body = (
      <>
        <p>Transakcja przebiegła pomyślnie</p>
        {redirectUrl && <p>Za chwilę będziesz przekierowany do serwisu</p>}
      </>
    )
  } else {
    body = (
      <>
        <p>Wystąpił bład</p>
        {redirectUrl && <p>Za chwilę będziesz przekierowany do serwisu</p>}
      </>
    )
  }

  return (
    <Box title='Weryfikacja transakcji'>
      <SuccessTransactionStyles>{body}</SuccessTransactionStyles>
    </Box>
  )
}

export default SuccessTransaction
