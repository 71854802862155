import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'reactstrap'
import NavMenu from './NavMenu'

const Layout = (props) => {
  return (
    <div>
      <NavMenu />
      <Container>
        <Outlet />
      </Container>
    </div>
  )
}

export default Layout
