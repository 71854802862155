import React from 'react'
import Box from './UI/Box'
import styled from 'styled-components'

const TransactionDescriptionStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  .line {
    margin: 0;
    flex-basis: 100%;
    margin: 0;
    &-data,
    &-amount {
      color: #282878;
      font-weight: bold;
    }
  }
`

const TransactionDescription = ({
  merchantDescription,
  description,
  amount
}) => {
  return (
    <Box>
      <TransactionDescriptionStyles>
        <p className='line'>
          Odbiorca płatności:{' '}
          <span className='line-data'>{merchantDescription}</span>
        </p>
        <p className='line'>
          Opis: <span className='line-data'>{description}</span>
        </p>
        <p className='line'>
          Kwota całkowita: <span className='line-amount'>{amount} PLN</span>
        </p>
      </TransactionDescriptionStyles>
    </Box>
  )
}

export default TransactionDescription
