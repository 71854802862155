import React from 'react'
import styled from 'styled-components'

const SpinnerStyles = styled.span`
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: ${(props) => (props.color ? props.color : '#fff')};
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const Spinner = ({ color }) => {
  return <SpinnerStyles color={color}></SpinnerStyles>
}

export default Spinner
