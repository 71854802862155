import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import BlikCode from './BlikCode'
import ErrorBox from './ErrorBox'
import PaymentGroups from './PaymentGroups'
import PersonalInformation from './PersonalInformation'
import TransactionDescription from './TransactionDescription'
import LoadingOverlay from './UI/LoadingOverlay'

const HomeStyles = styled.section`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  .paymentGroups {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
  }

  .paymentButton {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;

    span {
      padding: 10px 40px;
      border: none;
      background-color: #282878;
      cursor: pointer;
      color: white;

      &.disabled {
        cursor: not-allowed;
      }

      &:hover {
        background-color: rgb(40 40 120 / 90%);
      }
    }
  }
`

const Home = () => {
  const [searchParams] = useSearchParams()
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [name, setName] = useState(null)
  const [nameInData, setNameInData] = useState(false)
  const [nameValidationMessage, setNameValidationMessage] = useState(null)
  const [email, setEmail] = useState(null)
  const [emailInData, setEmailInData] = useState(false)
  const [emailValidationMessage, setEmailValidationMessage] = useState(null)
  const [selectedGroupId, setSelectedGroupId] = useState(null)
  const [blikCode, setBlikCode] = useState(null)
  const [blikCodeInData, setBlikCodeInData] = useState(false)
  const [blikCodeValidationMessage, setBlikCodeValidationMessage] =
    useState(null)
  const [isValid, setIsValid] = useState(false)
  const [paymentData, setPaymentData] = useState(null)
  const [isLocked, setIsLocked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [transactionTitle, setTransactionTitle] = useState(null)
  const [isBlikRetry, setIsBlikRetry] = useState(false)

  useEffect(() => {}, [])

  const validateInput = async (data) => {
    if (!data) return
    setIsLoading(true)
    setIsError(false)
    //call validate here
    try {
      let validateRequest = {
        id: data.id,
        amount: data.amount,
        crc: data.crc,
        md5sum: data.md5sum,
        online: data.online
      }
      let response = await fetch('/api/paybylink/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(validateRequest)
      })
      if (response.ok) {
        let responseData = await response.json()
        console.log('Success:', responseData)
        //this will be from api validate call
        data.merchantDescription =
          data.merchantDescription ?? responseData.merchantName
        setPaymentData(data)
        setEmailInData(data.email)
        setNameInData(data.name)

        if (data.group) {
          setSelectedGroupId(data.group)
        }
        if (data.name) {
          setName(data.name)
        }
        if (data.email) {
          setEmail(data.email)
        }
        setPaymentMethods(
          responseData.paymentMethods.map((p) => {
            return { ...p, selected: p.id == data.group }
          })
        )
        setIsError(false)
        setIsLoading(false)
      } else {
        setIsError(true)
        setIsLoading(false)
      }
    } catch (ex) {
      console.log(`in catch: ${ex}`)
      setIsError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let data = {
      id: searchParams.has('id') ? searchParams.get('id') : null,
      amount: searchParams.has('amount') ? searchParams.get('amount') : null,
      description: searchParams.has('description')
        ? searchParams.get('description')
        : null,
      crc: searchParams.has('crc') ? searchParams.get('crc') : null,
      md5sum: searchParams.has('md5sum') ? searchParams.get('md5sum') : null,
      online: searchParams.has('online') ? searchParams.get('online') : null,
      group: searchParams.has('group') ? searchParams.get('group') : null,
      resultUrl: searchParams.has('result_url')
        ? searchParams.get('result_url')
        : null,
      resultEmail: searchParams.has('result_email')
        ? searchParams.get('result_email')
        : null,
      merchantDescription: searchParams.has('merchant_description')
        ? searchParams.get('merchant_description')
        : null,
      customDescription: searchParams.has('custom_description')
        ? searchParams.get('custom_description')
        : null,
      returnUrl: searchParams.has('return_url')
        ? searchParams.get('return_url')
        : null,
      returnErrorUrl: searchParams.has('return_error_url')
        ? searchParams.get('return_error_url')
        : null,
      language: searchParams.has('language')
        ? searchParams.get('language')
        : null,
      email: searchParams.has('email') ? searchParams.get('email') : undefined,
      name: searchParams.has('name') ? searchParams.get('name') : undefined,
      address: searchParams.has('address') ? searchParams.get('address') : null,
      city: searchParams.has('city') ? searchParams.get('city') : null,
      zip: searchParams.has('zip') ? searchParams.get('zip') : null,
      country: searchParams.has('country') ? searchParams.get('country') : null,
      phone: searchParams.has('phone') ? searchParams.get('phone') : null,
      acceptTos: searchParams.has('accept_tos')
        ? searchParams.get('accept_tos')
        : null,
      expirationDate: searchParams.has('expiration_date')
        ? searchParams.get('expiration_date')
        : null,
      timehash: searchParams.has('timehash')
        ? searchParams.get('timehash')
        : null
    }

    validateInput(data)
  }, [searchParams])

  const selectedHandler = (id) => {
    if (isBlikRetry) return
    setPaymentMethods((prev) => {
      return prev.map((x) => {
        return { ...x, selected: x.id === id }
      })
    })
    setSelectedGroupId(id)
  }

  const nameChangeHandler = (event) => {
    if (isBlikRetry) return
    setName(event.target.value)
    setNameInData(true)
  }
  useEffect(() => {
    let msg = null
    if (nameInData && (!name || name.length === 0)) {
      msg = 'Nieprawidłowe imię i nazwisko'
    }
    setNameValidationMessage(msg)
  }, [name])

  const emailChangeHandler = (event) => {
    if (isBlikRetry) return
    setEmail(event.target.value)
    setEmailInData(true)
  }
  useEffect(() => {
    let msg = null
    if (
      emailInData &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      msg = 'Nieprawidłowy adres email'
    }
    setEmailValidationMessage(msg)
  }, [email])

  const blikCodeChangeHandler = (event) => {
    setBlikCode(event.target.value)
    setBlikCodeInData(true)
  }
  useEffect(() => {
    let msg = null
    if (blikCodeInData && !/^\d{6}$/.test(blikCode)) {
      msg = 'Nieprawidłowy kod blik'
    }
    setBlikCodeValidationMessage(msg)
  }, [blikCode])

  useEffect(() => {
    let isValidGroup = selectedGroupId != null && selectedGroupId != undefined

    let isValidName = name?.length > 0

    let isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    )

    let isBlikRequired = selectedGroupId == 150

    let isBlikValid = blikCodeInData && /^\d{6}$/.test(blikCode)

    let isValid =
      isValidGroup &&
      isValidName &&
      isValidEmail &&
      (!isBlikRequired || isBlikValid)

    setIsValid(isValid)
  }, [selectedGroupId, name, email, blikCode])

  const onSubmitData = async () => {
    if (!isValid || isLocked || isBlikRetry) return
    setIsLocked(true)

    let request = {
      ...paymentData,
      blikCode: blikCode,
      name: name,
      email: email,
      group: selectedGroupId
    }
    try {
      setErrorMessage(null)
      setIsLoading(true)
      let response = await fetch('/api/paybylink/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })

      if (response.status === 403) {
        setIsLocked(false)
        setIsError(true)
        setIsLoading(false)
        return
      }
      let responseBody = await response.json()
      if (response.status === 400) {
        if (
          responseBody.errors.Id ||
          responseBody.errors.Amount ||
          responseBody.errors.Md5sum
        ) {
          setIsError(true)
          setErrorMessage('Błędne metadane transakcji')
          setIsLoading(false)
          return
        }
        let hasUserValidationErrors = false

        if (responseBody.errors.Group) {
          hasUserValidationErrors = true
        }

        if (responseBody.errors.Name) {
          hasUserValidationErrors = true
          setNameInData(true)
          setNameValidationMessage(responseBody.errors.Name[0])
        }

        if (responseBody.errors.Email) {
          hasUserValidationErrors = true
          setEmailInData(true)
          setEmailValidationMessage(responseBody.errors.Email[0])
        }

        if (responseBody.errors.BlikCode) {
          hasUserValidationErrors = true
          setBlikCodeInData(true)
          setBlikCodeValidationMessage(responseBody.errors.BlikCode[0])
        }

        if (hasUserValidationErrors) {
          setIsLoading(false)
          setIsLocked(false)
          setIsValid(false)
          return
        }
        setIsLoading(false)
        setIsError(true)
        return
      }

      if (!response.ok) {
        setIsLoading(false)
        setIsError(true)
        return
      }

      if (responseBody.success) {
        window.location.href = responseBody.redirectUrl
        return
      }

      //not success blik
      if (!responseBody.success && request.group == 150) {
        //because not valid blik code, allow correction
        if (responseBody.errorMessage === 'ERR63') {
          setIsLoading(false)
          setBlikCodeInData(true)
          setBlikCodeValidationMessage('Nieprawidłowy kod blik')
          setTransactionTitle(responseBody.title)
          setIsBlikRetry(true)
          setIsLocked(false)
          setIsValid(false)
          return
        }

        window.location.href = responseBody.redirectUrl
        return
      }

      //another error
      setIsLoading(false)
      setIsError(true)
    } catch (ex) {
      setIsLocked(false)
      setIsError(true)
      setIsLoading(false)
    }
  }

  const onBlikRetry = async () => {
    if (!isValid || isLocked) return
    setIsLocked(true)

    let request = {
      ...paymentData,
      blikCode: blikCode,
      name: name,
      email: email,
      group: selectedGroupId,
      title: transactionTitle
    }
    try {
      setErrorMessage(null)
      setIsLoading(true)
      let response = await fetch('/api/paybylink/resendblikcode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })

      if (response.status === 403) {
        setIsLocked(false)
        setIsError(true)
        setIsLoading(false)
        return
      }
      let responseBody = await response.json()
      if (response.status === 400) {
        if (
          responseBody.errors.Id ||
          responseBody.errors.Amount ||
          responseBody.errors.Md5sum ||
          responseBody.errors.Title
        ) {
          setIsError(true)
          setErrorMessage('Błędne metadane transakcji')
          setIsLoading(false)
          return
        }

        if (responseBody.errors.BlikCode) {
          setBlikCodeInData(true)
          setBlikCodeValidationMessage(responseBody.errors.BlikCode[0])
          setIsLoading(false)
          setIsValid(false)
          setIsLocked(false)
          setIsBlikRetry(true)
          return
        }

        setIsLoading(false)
        setIsError(true)
        return
      }

      if (response.ok) {
        if (responseBody.success) {
          window.location.href = responseBody.redirectUrl
          return
        }

        //because not valid blik code, allow correction
        if (responseBody.errorMessage === 'ERR63') {
          setIsLoading(false)
          setBlikCodeInData(true)
          setBlikCodeValidationMessage('Nieprawidłowy kod blik')
          setIsBlikRetry(true)
          setIsValid(false)
          setIsLocked(false)
          return
        }

        window.location.href = responseBody.redirectUrl
        return
      }

      //another error
      setIsLoading(false)
      setIsError(true)
    } catch (ex) {
      setIsLocked(false)
      setIsError(true)
      setIsLoading(false)
    }
  }

  let body

  if (isLoading) {
    body = <LoadingOverlay />
  } else if (isError) {
    body = <ErrorBox errorMessage={errorMessage} />
  } else {
    body = (
      <HomeStyles>
        <TransactionDescription
          merchantDescription={paymentData?.merchantDescription}
          description={paymentData?.description}
          amount={paymentData?.amount}
        />
        <PaymentGroups
          paymentMethods={paymentMethods}
          onSelected={selectedHandler}
        />
        {selectedGroupId && (
          <PersonalInformation
            name={name}
            nameChangeHandler={nameChangeHandler}
            nameValidationMessage={nameValidationMessage}
            email={email}
            emailChangeHandler={emailChangeHandler}
            emailValidationMessage={emailValidationMessage}
          />
        )}
        {selectedGroupId && selectedGroupId == 150 && (
          <BlikCode
            blikCode={blikCode}
            blikCodeChangeHandler={blikCodeChangeHandler}
            blikCodeValidationMessage={blikCodeValidationMessage}
          />
        )}
        <div className='paymentButton'>
          <span
            className={`${!isValid || isLocked ? 'disabled' : ''}`}
            onClick={isBlikRetry ? onBlikRetry : onSubmitData}
          >
            Zapłać {paymentData?.amount} PLN
          </span>
        </div>
      </HomeStyles>
    )
  }
  return body
}

export default Home
