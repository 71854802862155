import React from 'react'
import { Container, Navbar } from 'reactstrap'
import './NavMenu.css'

const NavMenu = () => {
  return (
    <header>
      <Navbar
        className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3'
        light
      >
        <Container>
          <a href='https://ppss.pl'>
            <img src='logo.png' />
          </a>
        </Container>
      </Navbar>
    </header>
  )
}

export default NavMenu
