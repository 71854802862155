import React from 'react'
import styled from 'styled-components'
import Spinner from './Spinner'

const LoadingStyles = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`

const Loading = () => {
  return (
    <LoadingStyles>
      <Spinner color='#aabfff' />
    </LoadingStyles>
  )
}

export default Loading
