import React from 'react'

import styled from 'styled-components'

const PaymentGroupStyles = styled.div`
  cursor: pointer;
  width: 180px;
  height: 80px;
  border: 1px solid #d9e2ff;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.selected {
    border-width: 3px;
    border-color: #aabfff;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const PaymentGroup = ({ id, name, image, selected, onSelected }) => {
  return (
    <PaymentGroupStyles className={`${selected ? 'selected' : ''}`}>
      <img src={image} alt={name} onClick={() => onSelected(id)} />
    </PaymentGroupStyles>
  )
}

export default PaymentGroup
