import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ErrorTransaction from './components/ErrorTransaction'
import SuccessTransaction from './components/SuccessTransaction'
import Home from './components/Home'
import Layout from './components/Layout'
import './custom.css'

const App = (props) => {
  return (
    <BrowserRouter basename={props.baseUrl}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/returnErrorUrl/:hash' element={<ErrorTransaction />} />
          <Route path='/returnUrl/:hash' element={<SuccessTransaction />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
