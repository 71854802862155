import React from 'react'
import styled from 'styled-components'

const BoxStyles = styled.div`
  width: 100%;
  .header {
    background-color: #aabfff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.5em;
    text-align: center;
    margin: 0;
  }
  .content {
    width: 100%;
    border: 1px solid #aabfff;
    margin: 0;
    padding: 20px;
  }
`

const Box = ({ title, children }) => {
  return (
    <BoxStyles>
      {title && <div className='header'>{title}</div>}
      <div className='content'>{children}</div>
    </BoxStyles>
  )
}

export default Box
