import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Box from './UI/Box'
import LoadingOverlay from './UI/LoadingOverlay'

const ErrorTransactionStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  & > * {
    flex-basis: 100%;
  }

  & > p {
    text-align: center;
  }
`

const ErrorTransaction = () => {
  const { hash } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [redirectUrl, setRedirectUrl] = useState(null)

  const fetchStatusHandler = useCallback(async () => {
    try {
      let response = await fetch(`/api/paybylink/validate/${hash}`)
      if (response.ok) {
        let body = await response.json()
        if (body.returnUrl) {
          setRedirectUrl(body.returnUrl)
          setTimeout(() => {
            window.location.href = body.returnUrl
          }, 10000)
        }
      }
    } catch (ex) {}
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchStatusHandler()
  }, [fetchStatusHandler])

  let body

  if (isLoading) {
    body = <LoadingOverlay />
  } else {
    body = (
      <Box title='Błędna transakcja'>
        <ErrorTransactionStyles>
          <p>Wystąpił błąd w czasie transakcji</p>
          {redirectUrl && <p>Za chwilę będziesz przekierowany do serwisu</p>}
        </ErrorTransactionStyles>
      </Box>
    )
  }

  return body
}

export default ErrorTransaction
