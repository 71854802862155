import React from 'react'
import Box from './UI/Box'
import styled from 'styled-components'

const ErrorBoxStyles = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`

const ErrorBox = ({ errorMessage }) => {
  return (
    <Box title='Wystąpił błąd'>
      <ErrorBoxStyles>
        {errorMessage
          ? errorMessage
          : 'Wystąpił błąd. Prosimy spróbować ponownie'}
      </ErrorBoxStyles>
    </Box>
  )
}

export default ErrorBox
